import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const selfEfficacyItemConfig: MetricItemConfig[] = [
  {
    label: 'confident_ability',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I feel confident about my ability to do well in this class.',
    },
  },
  {
    label: 'confident_perform',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I am confident that I can perform effectively in this class.',
    },
  },
];

export const selfEfficacyConfig: CompositeMetricConfig = {
  label: 'self-efficacy',
  name: 'Self-Efficacy',
  items: selfEfficacyItemConfig,
  itemIndex: keyBy(selfEfficacyItemConfig, 'label'),
};
