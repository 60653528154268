import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const institutionalGmsItemConfig: MetricItemConfig[] = [
  {
    label: 'gmind_amount',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      en:
        'This instructor seems to believe that students have a certain ' +
        'amount of intelligence, and they really can’t do much to change it.',
    },
  },
  {
    label: 'gmind_intelligence',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      en:
        'This instructor seems to believe that your intelligence is ' +
        'something that you can’t change very much.',
    },
  },
];

export const institutionalGmsConfig: CompositeMetricConfig = {
  label: 'institutional-gms',
  name: 'Institutional Growth Mindset',
  items: institutionalGmsItemConfig,
  itemIndex: keyBy(institutionalGmsItemConfig, 'label'),
};
