import { useContext } from 'react';
import styled from 'styled-components/macro';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  Modal,
  Button,
  Row,
  Col,
  TextField,
  HelpText,
  InfoBox,
  Input,
  Select,
  Show,
  useFormError,
  Text,
} from '@perts/ui';

import { User } from 'models';
import {
  helpArticles,
  programAscendPattern,
  programCatalyzePattern,
  programElevatePattern,
  subjectAreas,
  USER_OWNED_TEAMS_MAX,
} from '../../../config';
import TermsContext, { Terms } from 'components/TermsContext';

const getClassAddFormSchema = (
  terms: Terms,
  facilitators: User[],
  programId: string,
) =>
  Yup.object().shape({
    name: Yup.string()
      .max(200, `${terms.class} Name must be at most 200 characters long.`)
      .required(`${terms.class} Name is required.`),
    subjectArea: Yup.string().when('isElevate', {
      is: (/* value */) => programElevatePattern.test(programId),
      then: (schema) => schema.required('Subject area is required.'),
      otherwise: (schema) => schema.notRequired(),
    }),
    facilitatorId: Yup.string()
      .required(`${terms.classManager} is required.`)
      .test(
        'too-many-classes',
        ({ value: facilitatorId }) => {
          const selectedFacilitator = facilitators.find(
            (f) => f.uid === facilitatorId,
          );

          if (!selectedFacilitator) {
            // Since the facilitatorId options are coming from the facilitators
            // array, this should not be possible, but make TypeScript happy.
            return '';
          }

          return (
            <>
              {selectedFacilitator.name} ({selectedFacilitator.email}) cannot be
              assigned to more than {USER_OWNED_TEAMS_MAX}{' '}
              {terms.classes.toLowerCase()}. Reach out to{' '}
              <a className="InfoBoxA" href="mailto:support@perts.net">
                support@perts.net
              </a>
              .
            </>
          );
        },
        (facilitatorId) => {
          const selectedFacilitator = facilitators.find(
            (f) => f.uid === facilitatorId,
          );

          if (!selectedFacilitator) {
            // Since the facilitatorId options are coming from the facilitators
            // array, this should not be possible, but make TypeScript happy.
            return true;
          }

          return selectedFacilitator.owned_teams.length < USER_OWNED_TEAMS_MAX;
        },
      ),
  });

export type ClassAddFormValues = {
  name: string;
  subjectArea: string;
  facilitatorId: string;
  redirectToRoster: boolean;
};

export type ClassAddFormProps = {
  close: () => void;
  currentUser: User;
  facilitators: User[];
  maySelectFacilitator: boolean;
  onSubmit: (values: ClassAddFormValues) => void;
  programId: string;
};

const SurveyPreviewLabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-bottom: 0;
  }
`;

const SurveyPreviewLabel = styled(Text)`
  font-weight: 700;
`;

const QuestionPreview = ({ className = '', programId }) => (
  <>
    {programAscendPattern.test(programId) ? (
      <>
        This question is about {className || <i>no name set</i>}.<br />I feel
        confident about my ability to do well in this class.
      </>
    ) : programCatalyzePattern.test(programId) ? (
      <>
        This question is about {className || <i>no name set</i>}.<br />
        Staff feel responsible to help each other do our best.
      </>
    ) : programElevatePattern.test(programId) ? (
      <>
        This question is about {className || <i>no name set</i>}.<br />
        This teacher treats me with respect.
      </>
    ) : null}
  </>
);

export const ClassesAddForm = ({
  close,
  currentUser,
  facilitators,
  maySelectFacilitator,
  onSubmit,
  programId,
}: ClassAddFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  const validFacilitators = maySelectFacilitator ? facilitators : [currentUser];

  const initialValues = {
    name: '',
    subjectArea: '',
    facilitatorId: '',
    redirectToRoster: false,
  };

  // Make sure there's something to display in the select field even if a
  // user's name isn't set.
  const facilitatorOptions = validFacilitators.map((user) => ({
    ...user,
    name: user.name || user.email,
  }));

  return (
    <Modal close={close}>
      <Modal.Title className="center">Create {terms.class}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={getClassAddFormSchema(terms, facilitators, programId)}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            await showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit, or wait for redirect.
            if (!values.redirectToRoster) {
              close();
            }
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
        validateOnBlur={false}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue, values }) => (
          <Form>
            <Row>
              <Col>
                <Input
                  aria-describedby="add-class-name-explainer"
                  id="inputName"
                  name="name"
                  label={`${terms.class} Name`}
                  labelPlacement="top"
                  component={TextField}
                  disabled={isSubmitting}
                  sublabel={
                    <p id="add-class-name-explainer">
                      <em>{terms.classNameExplainer}</em>
                    </p>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <SurveyPreviewLabelContainer>
                  <SurveyPreviewLabel>Question Preview</SurveyPreviewLabel>
                  <HelpText articleId={helpArticles.howToSeeFullSurvey}>
                    {`How can I preview the survey?`}
                  </HelpText>
                </SurveyPreviewLabelContainer>
              </Col>
            </Row>
            <Row>
              <Col>
                <InfoBox display="block">
                  <QuestionPreview
                    className={values.name}
                    programId={programId}
                  />
                </InfoBox>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* empty spacer for consistency */}
                &nbsp;
              </Col>
            </Row>
            <Show when={programElevatePattern.test(programId)}>
              <Row>
                <Col>
                  <Input
                    id="inputSubjectArea"
                    name="subjectArea"
                    label="Subject Area"
                    labelPlacement="top"
                    keyBy="key"
                    keyName="name"
                    options={subjectAreas}
                    placeholder="Select a subject area"
                    fullWidth
                    component={Select}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>
            </Show>
            <Row>
              <Col>
                <Input
                  id="inputContact"
                  name="facilitatorId"
                  label={terms.classManager}
                  labelPlacement="top"
                  helpText={
                    <HelpText articleId={helpArticles.whatIsAMember}>
                      {`What is a ${terms.classManager}?`}
                    </HelpText>
                  }
                  options={facilitatorOptions}
                  keyBy="uid"
                  keyName="name"
                  placeholder={`Select ${terms.aClassManager}`}
                  fullWidth
                  component={Select}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row justifyContent="space-between">
              <Col cols={4} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={4} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  color="secondary"
                  fullWidth
                  onClick={() => setFieldValue('redirectToRoster', true)}
                  disabled={!isValid || isSubmitting || !dirty}
                  data-testid="create-and-edit-roster"
                  fullHeight
                >
                  Create {terms.class} &amp; Edit Roster
                </Button>
              </Col>

              <Col cols={4} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting || !dirty}
                  loading={isSubmitting}
                  data-testid="submit"
                >
                  Create {terms.class}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
