import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const classroomCommunityItemConfig: MetricItemConfig[] = [
  {
    label: 'classroom_community_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'In this class, we have lots of opportunities to interact with ' +
        'each other.',
      es:
        'En esta clase, tenemos muchas oportunidades para interactuar ' +
        'entre nosotros.',
    },
  },
  {
    label: 'classroom_community_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This class is a welcoming place for everyone.',
      es: 'Esta clase es un lugar donde todos son bienvenidos.',
    },
  },
  {
    label: 'classroom_community_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'I feel comfortable sharing my thoughts and opinions in this ' +
        'class.',
      es:
        'Me siento cómodo compartiendo mis pensamientos y opiniones en ' +
        'esta clase.',
    },
  },
];

export const classroomCommunityConfig: CompositeMetricConfig = {
  label: 'classroom-community',
  name: 'Classroom Community',
  items: classroomCommunityItemConfig,
  itemIndex: keyBy(classroomCommunityItemConfig, 'label'),
};
