import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const studentVoiceItemConfig: MetricItemConfig[] = [
  {
    label: 'student_voice_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I have the opportunity to make choices about my work in this class.',
      es:
        'Tengo la oportunidad de tomar decisiones sobre mi trabajo en ' +
        'esta clase.',
    },
  },
  {
    label: 'student_voice_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'In this class, my ideas are taken seriously.',
      es: 'En esta clase, mis ideas son tomadas en serio.',
    },
  },
  {
    label: 'student_voice_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'This teacher responds to student suggestions to make our ' +
        'class better.',
      es:
        'Este maestro responde a las sugerencias de los estudiantes ' +
        'para mejorar nuestra clase.',
    },
  },
];

export const studentVoiceConfig: CompositeMetricConfig = {
  label: 'student-voice',
  name: 'Student Voice',
  items: studentVoiceItemConfig,
  itemIndex: keyBy(studentVoiceItemConfig, 'label'),
};
