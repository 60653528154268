import { useState } from 'react';
import { noop } from 'lodash';
import styled from 'styled-components/macro';

import {
  selectUserIsGroupManager,
  useGroupsQuery,
  useNetworksQuery,
} from 'models';
import { toGroup, toNetwork } from 'pages';

import {
  Card,
  Col,
  IconButton,
  IconChevronRight,
  IconUserCircle,
  Row,
  Show,
} from '@perts/ui';
import { CardButton } from 'components/CardButton';
import FakeLink from 'components/FakeLink';
import { useTerms } from 'components/TermsContext';

import { ImpersonateUser } from './ImpersonateUser';

const RowIndented = styled(Row)`
  padding-left: 20px;
`;

const SearchUserHeader = styled.h3`
  display: inline;
  font-weight: bold;
`;

export const SearchUserCard = ({ user }) => {
  // Allows loading messages to be hidden until the user has interacted.
  const [loadUser, setLoadUser] = useState(false);
  const terms = useTerms();

  const {
    data: groups = [],
    isIdle: groupsIsIdle,
    isLoading: groupsIsLoading,
  } = useGroupsQuery({ userId: user.uid }, { enabled: loadUser });

  const {
    data: networks = [],
    isIdle: networksIsIdle,
    isLoading: networksIsLoading,
  } = useNetworksQuery({ userId: user.uid }, { enabled: loadUser });

  const isIdle = groupsIsIdle || networksIsIdle;
  const isLoading = groupsIsLoading || networksIsLoading;

  return (
    <Card key={user.uid}>
      <Card.Title>
        <Row>
          <Col>
            <SearchUserHeader>{user.name}</SearchUserHeader>{' '}
            <span>
              &lt;<a href="mailto:">{user.email}</a>&gt;
            </span>
          </Col>

          <Col shrink>
            <ImpersonateUser uid={user.uid} />
          </Col>

          <Col shrink>
            {isIdle && (
              <FakeLink onClick={() => setLoadUser(true)}>Load</FakeLink>
            )}

            {isLoading && <span>Loading...</span>}
          </Col>
        </Row>
      </Card.Title>

      {!isIdle && !isLoading && (
        <Card.Content>
          <h3>{terms.networks}:</h3>
          {networks.map((network) => (
            <RowIndented key={network.uid}>
              <Col>{network.name}</Col>
              <Col shrink hAlign="flex-end">
                <CardButton
                  to={toNetwork(network.uid)}
                  color="text"
                  iconRight={<IconChevronRight />}
                >
                  View
                </CardButton>
              </Col>
            </RowIndented>
          ))}
          <Show when={networks.length === 0}>
            <RowIndented>
              <Col>
                <em>none</em>
              </Col>
            </RowIndented>
          </Show>
          <h3>{terms.groups}:</h3>
          {groups.map((group) => (
            <RowIndented key={group.uid}>
              <Col>{group.name}</Col>

              <Show when={selectUserIsGroupManager(user, group.uid)}>
                <Col shrink>
                  <IconButton
                    aria-label={`Is ${terms.groupManager}`}
                    icon={<IconUserCircle />}
                    state="active"
                    onClick={noop}
                  />
                </Col>
              </Show>

              <Col shrink hAlign="flex-end">
                <CardButton
                  to={toGroup(group.uid)}
                  color="text"
                  iconRight={<IconChevronRight />}
                >
                  View
                </CardButton>
              </Col>
            </RowIndented>
          ))}
          <Show when={groups.length === 0}>
            <RowIndented>
              <Col>
                <em>none</em>
              </Col>
            </RowIndented>
          </Show>
        </Card.Content>
      )}
    </Card>
  );
};
