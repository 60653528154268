import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const teacherCaringItemConfig: MetricItemConfig[] = [
  {
    label: 'teacher_caring_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I feel like this teacher is glad that I am in their class.',
      es: 'Siento que mi maestro se alegra de que estoy en su clase.',
    },
  },
  {
    label: 'teacher_caring_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This teacher cares about my life outside of school.',
      es: 'Este maestro se preocupa por mi vida fuera de la escuela.',
    },
  },
  {
    label: 'teacher_caring_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This teacher treats me with respect.',
      es: 'Este maestro me trata con respeto.',
    },
  },
];

export const teacherCaringConfig: CompositeMetricConfig = {
  label: 'teacher-caring',
  name: 'Teacher Caring',
  items: teacherCaringItemConfig,
  itemIndex: keyBy(teacherCaringItemConfig, 'label'),
};
