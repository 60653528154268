import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const socialConnectednessItemConfig: MetricItemConfig[] = [
  {
    label: 'connect_students',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'In this class, I can rely on other students for academic support.',
    },
  },
  {
    label: 'connect_feedback',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I receive clear academic feedback from this instructor. ',
    },
  },
  {
    label: 'connect_instructor',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I can communicate with this instructor about this class as needed.',
    },
  },
];

export const socialConnectednessConfig: CompositeMetricConfig = {
  label: 'social-connectedness',
  name: 'Social Connectedness',
  items: socialConnectednessItemConfig,
  itemIndex: keyBy(socialConnectednessItemConfig, 'label'),
};
