import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24MeaningfulWorkItemConfig: MetricItemConfig[] = [
  {
    label: 'meaningful_work_elem_interesting',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'This teacher makes learning interesting.',
      es: 'Este maestro hace el aprendizaje interesante.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'meaningful_work_elem_connect',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'In this class, we connect what we learn to the real world.',
      es: 'En esta clase, conectamos lo que aprendemos con la vida real.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24MeaningfulWorkConfig: CompositeMetricConfig = {
  label: 'meaningful-work-elem',
  name: 'Meaningful Work (grades 3-5)',
  items: elementary24MeaningfulWorkItemConfig,
  itemIndex: keyBy(elementary24MeaningfulWorkItemConfig, 'label'),
};
