import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24FeedbackForGrowthItemConfig: MetricItemConfig[] = [
  {
    label: 'feedback_for_growth_elem_mistakes',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'In this class, we learn from our mistakes.',
      es: 'En esta clase, aprendemos de nuestros errores.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'feedback_for_growth_elem_school',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher thinks I can do well in school.',
      es: 'Mi maestro piensa que me puede ir bien en la escuela.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'feedback_for_growth_elem_work',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher gives me ideas for how to make my work better.',
      es: 'Mi maestro me da ideas para mejorar mi trabajo.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24FeedbackForGrowthConfig: CompositeMetricConfig = {
  label: 'feedback-for-growth-elem',
  name: 'Feedback For Growth (grades 3-5)',
  items: elementary24FeedbackForGrowthItemConfig,
  itemIndex: keyBy(elementary24FeedbackForGrowthItemConfig, 'label'),
};
