import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { elevateElementary24Options } from './answerOptions';

export const elementary24TeacherCaringItemConfig: MetricItemConfig[] = [
  {
    label: 'teacher_caring_elem_glad',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher is glad that I am in their class.',
      es: 'Mi maestro esta contento que estoy en su clase.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'teacher_caring_elem_respect',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher treats me with respect.',
      es: 'Mi maestro me trata con respeto.',
    },
    options: elevateElementary24Options,
  },
  {
    label: 'teacher_caring_elem_cares',
    likertN: 5,
    minGood: 4,
    prompts: {
      en: 'My teacher cares about my life outside of school.',
      es: 'Mi maestro se preocupa por mi vida fuera de la escuela.',
    },
    options: elevateElementary24Options,
  },
];

export const elementary24TeacherCaringConfig: CompositeMetricConfig = {
  label: 'teacher-caring-elem',
  name: 'Teacher Caring (grades 3-5)',
  items: elementary24TeacherCaringItemConfig,
  itemIndex: keyBy(elementary24TeacherCaringItemConfig, 'label'),
};
