import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const socialBelongingItemConfig: MetricItemConfig[] = [
  {
    label: 'belong_comfort',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I feel comfortable in this class.',
    },
  },
  {
    label: 'belong_accepted',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I feel accepted in this class.',
    },
  },
  {
    label: 'belong_beself',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I feel like I can be myself in this class.',
    },
  },
  {
    label: 'belong_belong',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I feel like I belong in this class.',
    },
  },
];

export const socialBelongingConfig: CompositeMetricConfig = {
  label: 'social-belonging',
  name: 'Social Belonging',
  items: socialBelongingItemConfig,
  itemIndex: keyBy(socialBelongingItemConfig, 'label'),
};
