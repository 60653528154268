// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

const options = {
  [locales.ENGLISH]: [
    {
      label: 'Hardly ever',
      value: 1,
    },
    {
      label: 'Sometimes',
      value: 2,
    },
    {
      label: 'Often',
      value: 3,
    },
    {
      label: 'Always',
      value: 4,
    },
  ],
  [locales.SPANISH]: [
    {
      label: 'Casi nunca',
      value: 1,
    },
    {
      label: 'Algunas veces',
      value: 2,
    },
    {
      label: 'Con frecuencia',
      value: 3,
    },
    {
      label: 'Siempre',
      value: 4,
    },
  ],
};

export const schoolBelongingItemConfig: MetricItemConfig[] = [
  {
    label: 'school_belonging_care',
    prompts: {
      [locales.ENGLISH]: `People care about me in this school.`,
      [locales.SPANISH]: `La gente se preocupa por mí en esta escuela.`,
    },
    options,
    minGood: 3,
  },
  {
    label: 'school_belonging_myself',
    prompts: {
      [locales.ENGLISH]: `I can really be myself in this school.`,
      [locales.SPANISH]: `En esta escuela, puedo ser yo mismo.`,
    },
    options,
    minGood: 3,
  },
];

export const schoolBelongingConfig: CompositeMetricConfig = {
  label: 'school-belonging',
  name: 'Social Belonging',
  items: schoolBelongingItemConfig,
  itemIndex: keyBy(schoolBelongingItemConfig, 'label'),
};
